import React from 'react'
import HeroBgAnimation from '../HeroBgAnimation'
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle,SocialMediaIcons,SocialMediaIcon, ResumeButton } from './HeroStyle'
import HeroImg from '../../assets/images/sapiens1.jpg'
import Typewriter from 'typewriter-effect';
import { Bio } from '../../data/constants';
import { Link } from 'react-router-dom';

const HeroSection = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroBgAnimation />
                </HeroBg>
                <HeroInnerContainer >
                    <HeroLeftContainer id="Left">
                        <Title>Hi, I am <br /> <Typewriter
                                    options={{
                                        strings: Bio.name,
                                        autoStart: true,
                                        loop: true,
                                        color: "purple"
                                    }}
                                /></Title>
                        {/* <TextLoop>
                            I am a
                            <Span>
                                {Bio.roles}
                            </Span>
                        </TextLoop> */}
                        <SubTitle>{Bio.description.substring(0, 289)} <Link to='/about' style={{letterSpacing: "4px", textDecoration: "none", fontWeight: "bold"}}>...</Link></SubTitle>
                        
                        <a className='add-button' href={Bio.resume} style={{maxWidth: "300px", textTransform: "uppercase", textAlign: "center"}} target="display">
                            <span>Check Resume</span>
                        </a>
                    </HeroLeftContainer>

                    <HeroRightContainer id="Right">
                        <Img src={HeroImg} alt="hero-image" />
                    </HeroRightContainer>
                </HeroInnerContainer>
            </HeroContainer>
        </div>
    )
}

export default HeroSection