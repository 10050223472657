export const Bio = {
  name: "Sapiens Ndatabaye",
  roles: [
    "Information Management systems",
    "Research",
    "monitoring and evaluation systems",
    "Geospatial Analysis and remote sensing ",
    "Capacity building and mentorship"
  ],
  description:
    "Humbly, I am an ardent practitioner in the realms of geospatial analysis, artificial intelligence, R scripts, and Python. Throughout the past nine years, I have wholeheartedly dedicated myself to crafting impactful information management tools tailored for intricate humanitarian scenarios. As a steadfast member of United Nations Agencies and International Organizations, I have had the privilege of serving as a full-time employee, successfully spearheading the implementation of over fifteen systems across nations prone to crises. My profound expertise encompasses not only identifying critical gaps but also devising and executing information management systems, automating their updates and monitoring processes, empowering individuals through capacity building, providing insightful guidance, and fostering collaborative networks for meaningful partnerships.\nWhile I am ceaselessly driven by my relentless pursuit of innovation and creation, I diligently remain at the forefront of technological advancements. I accomplish this by diligently engaging in deep professional training programs, attending insightful webinars, and staying abreast of the latest developments through reputable journals. In order to maintain a harmonious equilibrium between my personal and professional realms, I cherish moments of laughter and togetherness with my loved ones, endeavoring to inspire the younger generation through individual interactions and thought-provoking conferences. Furthermore, I devote myself to the noble act of apostolate.\n In the depths of my being, I am unwavering in my belief that a brighter world, brimming with joyous individuals, is not only attainable but also worth tirelessly striving for",
  github: "",
  resume: "",
  linkedin: "https://www.linkedin.com/in/sapiens-ndatabaye-227425165/",
  twitter: "",
  insta: "",
  facebook: "",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
    ],
  },
  {
    title: "Other",
    skills: [
      {
        name: "Geospatial analysis",
        image:
          "https://i.ibb.co/YbfvK9K/Pngtree-blue-connecting-network-world-map-7325681.png",
      },
      {
        name: "Information Management",
        image:
          "https://i.ibb.co/y6x2K7x/kisspng-data-science-big-data-predictive-analytics-data-an-vector-thinking-lamp-5a99aa260b2b14-33035.png",
      },
      {
        name: "Team Leadership",
        image:
          "https://i.ibb.co/THW5FQ5/kisspng-senior-management-business-leadership-meeting-icon-image-free-5ab02e2d5e6890-039010131521495.png",
      },
    ],
  },
];

export const experiences = [
  {
    id: 12,
    img: "https://media.licdn.com/dms/image/D4D0BAQEra4h9cCB1Ww/company-logo_100_100/0/1693810512548/paris_lodron_universitaet_salzburg_logo?e=1711584000&v=beta&t=e2c1cZILY8m3WSp4gnihqXBDdZ2oWW4MzhFauGyVT0k",
    role: "Senior Earth Observation and geospatial analysis specialist",
    company: "Paris Lodron Universität Salzburg",
    date: "Dec 2023 - Present",
    desc: "By 2050, our world will confront unprecedented challenges due to climate change. The urgency is palpable—we stand at a pivotal moment in history. The need for high-caliber skills to combat these challenges has never been more vital. Thrilled to embark on a role that aims to pave the way for a brighter future amidst these imminent trials.",
    location: "Dakar, Senegal",
    skills: [

      "Geospatial Analysis",
      "Data Processing",
      "Spatial Modeling",
      "Visualization",
      "Vulnerability Assessment",
      "Integration with Social Sciences",

      "Information Management:",
      "Coordination",
      "Quality Assurance",
      "Metadata Management",
      "Sharing Platforms",
      "Capacity Building",
      "Risk Management",

      "Monitoring and Evaluation:",
      "Performance Tracking",
      "Metrics Development",
      "Feedback Mechanisms",

      "Decision-Maker Analysis:",
      "Policy Insights",
      "Decision Support Systems",
      "Stakeholder Engagement",

      "Modeling and Predictions:",
      "Predictive Modeling",
      "Scenario Planning",
    ],
    moreDescription: "Coordination: Establish and maintain an information management network to facilitate humanitarian information exchange and the promotion of data and information standards; provide training and expertise on the use and development of information management tools and platforms; advocate for the use of data standards and common platforms, and for the open exchange of information. \n•Web Management Manage content on relevant web platforms, provide overall quality control for the platform and ensure content is current, comprehensive and follows relevant metadata standards; work with external counterparts on related web platforms such as agency and cluster websites and ReliefWeb to facilitate cross-site search and interoperability.\n•Data Management Design, develop and manage databases, spreadsheets and other data tools; understand, document, and ensure the quality of high-value humanitarian data for accuracy, consistency and comparability; consolidate operational information on a regular schedule to support analysis. \n•Data Analysis Organize, design and carry out the evaluation and analysis of location specific datasets through meaningful statistical techniques; understand, document and ensure the quality of high-value humanitarian data for accuracy, consistency and comparability. \n•Assessment Analysis Support assessment and needs analysis activities; provide advice on assessment design to ensure data quality; manage platforms and tools that support data collection and analysis.\n•Geographic Information System (GIS) & Mapping Develop and maintain spatial baseline and operational datasets in accordance with relevant standards and guidance; produce and update high-quality map products and online services; \n•Visualization Produce and update information products such as reports, charts, and infographics by turning data into graphical products to convey messages and a stor",
    doc: ""
  },
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/C4E0BAQFx1jz-GVYMyA/company-logo_100_100/0/1661177755972?e=1705536000&v=beta&t=lwIx6ziNRJ_vXbANbYHiLbETywWqE5gfsIyS9_lhHZg",
    role: "Regional Information Management Officer - West and Central Africa (ROWCA)",
    company: "United Nations OCHA",
    date: "May 2022 - Present",
    desc: "Coordination, Web Management, Data Management, Data Analysis, Assessmenent Analysis, Geographic Information Systems (GIS) & Mapping, Visualization",
    location: "Dakar, Senegal",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    moreDescription: "Coordination: Establish and maintain an information management network to facilitate humanitarian information exchange and the promotion of data and information standards; provide training and expertise on the use and development of information management tools and platforms; advocate for the use of data standards and common platforms, and for the open exchange of information. \n•Web Management Manage content on relevant web platforms, provide overall quality control for the platform and ensure content is current, comprehensive and follows relevant metadata standards; work with external counterparts on related web platforms such as agency and cluster websites and ReliefWeb to facilitate cross-site search and interoperability.\n•Data Management Design, develop and manage databases, spreadsheets and other data tools; understand, document, and ensure the quality of high-value humanitarian data for accuracy, consistency and comparability; consolidate operational information on a regular schedule to support analysis. \n•Data Analysis Organize, design and carry out the evaluation and analysis of location specific datasets through meaningful statistical techniques; understand, document and ensure the quality of high-value humanitarian data for accuracy, consistency and comparability. \n•Assessment Analysis Support assessment and needs analysis activities; provide advice on assessment design to ensure data quality; manage platforms and tools that support data collection and analysis.\n•Geographic Information System (GIS) & Mapping Develop and maintain spatial baseline and operational datasets in accordance with relevant standards and guidance; produce and update high-quality map products and online services; \n•Visualization Produce and update information products such as reports, charts, and infographics by turning data into graphical products to convey messages and a stor",
    doc: ""
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/C560BAQH4bjHYChed-A/company-logo_100_100/0/1551773807642?e=1705536000&v=beta&t=d0cYBbUQ1of9fop6scgIp19qDVCf-0Ir5ORlZ9A3KBs",
    role: "Team Leader",
    company: "HEALTH SYSTEMS CONSULT LIMITED (HSCL)",
    date: "Feb 2022 - May 2022",
    desc: "Coordinate data and information management activities at the regional level",
    location: "Ruaraka, Nairobi County, Kenya",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Coordinate data and information management activities at the regional level: Participate in the relevant data and information management team and activities, contribute to the design and implement data strategies and systems. , assist the data team and others communicate the status, value, and importance of data, support the collection, storage, management, and protection of data in the region, support consolidation of data systems, including the interoperability of operational data and systems, ensure consistency of data and results for country, global and regional analysis, including cross border analysis and response, identify risks and opportunities based on insights derived from data, support the coordination with HQ and partners on data and information management eff orts and mechanisms, lead or take part the relevant IM networks, improve data quality in the region through field support, capacity building, monitoring and feedback.\nSupport the analysis of primary and secondary data for interpretation and evidence-informed decision making, support the monitoring of data and information management activities in the relevant offices for integrity, veracity, reliability and credibility and compliance with policies, document needs and capacities for data management activities, provide technical and training support to operations, support assessments and situational analysis process with analytical frameworks, data collection, and analysis (standards, methodologies, support planning process (standards, methodologies) quality assurance and technical advice on Theories of Change and indicators, support monitoring by providing quality assurance and technical advice to the monitoring methodologies, data collection and analysis, review and assess region-specific data systems and needs as well as provide recommendations for solutions, undertake data and information management activities."
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/C4D0BAQFvbEf0zO71QA/company-logo_100_100/0/1636965141867?e=1705536000&v=beta&t=0EOvAkXVoLIidniRemJwUWCiTP_SqiMawFNmaoBu7tQ",
    role: "Chief Executive Officer",
    company: "International Seekers",
    date: "Feb 2022 - May 2022",
    desc: "Lead and undertake data management activities (definition of needs, data collection, analysis, storage, and dissemination)",
    location: "Ruaraka, Nairobi County, Kenya",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Lead and undertake data management activities (definition of needs, data collection, analysis, storage, and dissemination), lead and undertake geospatial analysis, statistical analysis, targeting, surveys, indicator definition and other data management activities, consolidate data and information for analysis, support analysis for comparative country, regional and global analysis, including political and socio-economic trends, take part in planning processes, support cross-country assessment and situational analysis.\nUndertake the analysis of trends concerning changes at the outcome and impact levels, develop information management products such as indicators, templates, maps and dashboards, help ensure data is curated and stored in data registries and libraries in accordance with standards, provide data tracking systems, support solutions for meeting `communication with communities.\nLook for partnerships, design and implement a consolidated MEAL system for missions. Develop project proposals based on MEAL requirements and developing the required information management tools. Coordinate the process for establishing MEAL tools, information management systems and new technologies. Conduct data quality assessments for both emergency and development programs, develop response plans to identified problems and correct the information management system. Capacity building of MEAL staff. Coordinate the MEAL activities of organization's rapid response mechanism."
  },
  {
    id: 3,
    img: "https://media.licdn.com/dms/image/C4D0BAQFx8G_rwm6FiA/company-logo_100_100/0/1553077083703?e=1705536000&v=beta&t=uEUGDthbNi7sldd6BN15jWy3cix5_oXQF9-RHnpSk9I",
    role: "Information Management Specialist",
    company: "International Seekers",
    date: "Jul 2020 - Dec 2021",
    desc: "Data Management: Design, develop and manage databases, spreadsheets, and other data tools",
    location: "Democratic Republic of the Congo",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Data Management: Design, develop and manage databases, spreadsheets, and other data tools; understand, document, and ensure the quality of high-value humanitarian data for accuracy, consistency, and comparability; consolidate operational information on a regular schedule to support analysis.\nGeographic Information System (GIS)& Mapping: Develop and maintain spatial baseline and operational datasets in accordance with relevant standards and guidance; produce and update high-quality map products and online services.\nWeb Mastering: develop websites, implement the digital marketing strategy, manage content on relevant web platforms, provide overall quality control for the platform and ensure content is current, comprehensive and follows relevant metadata standards; work with external counterparts on related web platforms such as agency and cluster websites and ReliefWeb to facilitate cross-site search and interoperability."
  },
  {
    id: 4,
    img: "https://media.licdn.com/dms/image/C4D0BAQHSpcWCr2G5Wg/company-logo_100_100/0/1519874712296?e=1705536000&v=beta&t=KVz_vrH8O1vMAEOBBn2JflkG_xrAiZ2UUqILp4dNofs",
    role: "MEAL, assessment, and GIS Focal Point (3 Countries) ",
    company: "arx iT (Conseil & Réalisations SIG)",
    date: "Jul 2020 - Dec 2021",
    desc: "Data Management: Design, develop and manage databases, spreadsheets, and other data tools",
    location: "Kigali, Kigali City, Rwanda",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Develop, innovate, and design global information management systems (MEAL, GIS, data management, emergency, and assessment) of implemented projects. Improve the information management system and coordinate the reporting process. Develop and monitor project management plans to document performance and alert on failures. Provide support to ensure results-based management. Conduct evaluations (baseline, mid-term and final) to track the pathway and measure the impact of projects. Capacity building of MEAL staff. Setup the community accountability mechanism. Provide support in the development of new projects."
  },
  {
    id: 5,
    img: "https://media.licdn.com/dms/image/C4D0BAQHSpcWCr2G5Wg/company-logo_100_100/0/1519874712296?e=1705536000&v=beta&t=KVz_vrH8O1vMAEOBBn2JflkG_xrAiZ2UUqILp4dNofs",
    role: "MEAL, assessment, and GIS Focal Point (3 Countries) ",
    company: "arx iT (Conseil & Réalisations SIG)",
    date: "Jul 2020 - Dec 2021",
    desc: "Data Management: Design, develop and manage databases, spreadsheets, and other data tools",
    location: "Kigali, Kigali City, Rwanda",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Develop, innovate, and design global information management systems (MEAL, GIS, data management, emergency, and assessment) of implemented projects. Improve the information management system and coordinate the reporting process. Develop and monitor project management plans to document performance and alert on failures. Provide support to ensure results-based management. Conduct evaluations (baseline, mid-term and final) to track the pathway and measure the impact of projects. Capacity building of MEAL staff. Setup the community accountability mechanism. Provide support in the development of new projects."
  },
  {
    id: 6,
    img: "https://media.licdn.com/dms/image/C4E0BAQEofQfdKfpLDg/company-logo_100_100/0/1648174655294?e=1705536000&v=beta&t=RsK-Fw-t25Xwe3-a-wYfLNSBuHURMc5L2CK4w_Af9Ok",
    role: "Country Geographic Information System specialist (DRC-RCA)",
    company: "arx iT (Conseil & Réalisations SIG)",
    date: "Jul 2019 - jul 2020",
    desc: "Support the development of M&E tools (Theory of Change, Logical Framework, PIRS, PMP, Indicator Performance Tracking Table and MEAL strategy).",
    location: "Kinshasa Congo (RDC)",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Support the development of M&E tools (Theory of Change, Logical Framework, PIRS, PMP, Indicator Performance Tracking Table and MEAL strategy). Design appropriate complex databases to facilitate data management and processing. Development of tools and facilitation of the lessons learned capture, document, and capitalization process. Provide training of MEAL staff. Technical support in the organization of the baseline evaluation. Support CoP in reports writing.\n Data Management: Design, develop and manage databases, spreadsheets, and other data tools; understand, document, and ensure the quality of high-value humanitarian data for accuracy, consistency, and comparability; consolidate operational information on a regular schedule to support analysis.\n Geographic Information System (GIS)& Mapping: Develop and maintain spatial baseline and operational datasets in accordance with relevant standards and guidance; produce and update high-quality map products and online services.\n Visualization: Produce and update information products such as reports, charts, and infographics by turning data into graphical products to convey messages and a storyline; develop advocacy materials including posters, presentations, and other visual materials."
  },
  {
    id: 7,
    img: "https://media.licdn.com/dms/image/C560BAQGRnKYLxcr0TQ/company-logo_100_100/0/1597329365111?e=1705536000&v=beta&t=_6MOYDFw9mM_a8sD4TTr4Uv3Y0P9b4Byjfzg_FBFLGk",
    role: "Information Management Expert",
    company: "Counterpart International",
    date: "Jan 2018 - Jun 2019",
    desc: "Support the analysis of primary and secondary data for interpretation and evidence-informed decision-making,",
    location: "Kinshasa Congo (RDC)",
    skills: [
      "HTML",
      "PHP",
      "Communication",
      "Management",
      "Data Management",
      "Mining Exploration",
      "Python",
      "R",
      "Team Management",
      "Team Work",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS product",
      "Geographic Information Systems (GIS)",
      "Team Coordination",
      "Risk Assessment",
      "Team building",
      "Team Leadership",
      "MySQL",
      "Microsoft Power BI",
      "Tableau",
      "RStudio",
      "Data Analysis",
      "Analytical skills",
      "Adobe illustrator",
      "Adobe inDesign",
      "Data visualization"
    ],
    doc: "",
    moreDescription: "Support the analysis of primary and secondary data for interpretation and evidence-informed decision-making, support the monitoring of data and information management activities in the relevant offices for integrity, veracity, reliability and credibility, and compliance with policies, document needs, and capacities for data management activities, provide technical and training support to operations, support assessments and situational analysis process with analytical frameworks, data collection, and analysis (standards, methodologies, support planning process (standards, methodologies) quality assurance and technical advice on Theories of Change and indicators, support monitoring by providing quality assurance and technical advice to the monitoring methodologies, data collection and analysis, review and assess region-specific data systems and needs as well as provide recommendations for solutions, undertake data and information management activities.\nGIS & spatial analysis: present the results of the study in the form of dashboards or thematic maps with Power BI or ArcGIS Dashboard. Aggregate data from various data collection platforms and databases, review, validate, process and analyze these data, and share the information (in dashboard format) with the coordination to facilitate decision making and reporting. Support the CoP in writing reports. Capacity building of MEAL staff."
  },
  {
    id: 8,
    img: "https://media.licdn.com/dms/image/C4D0BAQEIG5RkDRNPvg/company-logo_100_100/0/1574431121488?e=1705536000&v=beta&t=hXDIlJ7mq9dMvFqDgVfanQ7Wre0lqCBw-vL-C04RuMg",
    role: "Spatial analyst",
    company: "Orange",
    date: "Sep 2016 - Dec 2017",
    desc: "Support the analysis of primary and secondary data for interpretation and evidence-informed decision-making,",
    location: "Bukavu, Sud-Kivu, Congo (RDC)",
    skills: [
      "English",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS Products",
      "Geographic Information Systems (GIS)",
      "MySQL"
    ],
    doc: "",
    moreDescription: "Assessment Analysis: Support assessment and needs analysis activities, including market needs overviews, secondary data reviews, preliminary scenario definitions; provide advice on assessment design to ensure data quality; manage platforms and tools that support data collection and analysis such as KoBo Toolbox and Open Data Kit (ODK).\nData cleaning: Provide technical review of data collected, organize the data, develop storage system and automatic process through R scripts or python.\nGeographic Information System (GIS)& Mapping: Develop and maintain spatial baseline and operational datasets in accordance with relevant standards and guidance; produce and update high-quality map products with land studies (Topography, population density).\nImplementation of the MEAL operational plan of the program “Orange Money” Design the methodology to be used in evaluations. Design indicators data collection tools. Promote the use of new technology in data collection, processing, and visualization. Design and management of databases. Support field team in the consolidation of reports. Organize the activity review workshops. Build the capacity of MEAL staff."
  },
  {
    id: 9,
    img: "https://media.licdn.com/dms/image/C4D0BAQHclhjPX0QtJw/company-logo_100_100/0/1519930233270?e=1705536000&v=beta&t=Ta4cml5dkm6v9oArjHdvyWFM1OZZILdvLRiTEufIrjE",
    role: "Monitoring and Evaluation Expert",
    company: "HOPE International",
    date: "Mar 2013 - Aug 2016",
    desc: "Follow up on the program implementation plan. Generate monthly indicator reports. Ensure quality and timely submission of monthly reports from components of the program.",
    location: "Bukavu, Sud-Kivu, Congo (RDC)",
    skills: [
      "English",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS Products",
      "Geographic Information Systems (GIS)",
      "MySQL"
    ],
    doc: "",
    moreDescription: "Follow up on the program implementation plan. Generate monthly indicator reports. Ensure quality and timely submission of monthly reports from components of the program. Organize the activities review workshops. Recruit and train investigators for data collection. Design the project activities database. Coordinate assessments, data processing, analysis, and report writing. Set up and manage the accountability mechanism. Supervise the acquisition of information technology tools and systems necessary for data management."
  },
  {
    id: 10,
    img: "https://media.licdn.com/dms/image/C4D0BAQHclhjPX0QtJw/company-logo_100_100/0/1519930233270?e=1705536000&v=beta&t=Ta4cml5dkm6v9oArjHdvyWFM1OZZILdvLRiTEufIrjE",
    role: "Monitoring and Evaluation Expert",
    company: "HOPE International",
    date: "Feb 2010 - Dec 2012",
    desc: "Coordinate the program process, ensure data consolidation and timely compilation and reporting of data.",
    location: "Bukavu, South Kivu, Democratic Republic of the Congo",
    skills: [
      "English",
      "Microsoft Excel",
      "Remote Sensing",
      "Cartography",
      "ArcGIS Products",
      "Geographic Information Systems (GIS)",
      "MySQL"
    ],
    doc: "",
    moreDescription: "Coordinate the program process, ensure data consolidation and timely compilation and reporting of data. Coordinate activities, results tracking, analysis, and bi-annual report writing. Capture and document lessons learned and promote the capitalization of best practices. Conduct weekly meetings with stakeholders to assess the quality of program activities. Collect indicators data and update the program database"
  },
];

export const education = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/C4E0BAQGn9Kjb4gd5Ow/company-logo_100_100/0/1633938162434?e=1705536000&v=beta&t=hRKIhNT8oUvb-1HxXGGdpc7uTU8OcuKtBzHLp0ArzrU",
    school: "KALU Institute",
    date: "May 2022 - May 2023",
    grade: "Master",
    desc: "Activities and societies: Preparedness,, coordination,Response, Information management, publication",
    degree: "Master's degree, International Cooperation and humanitarian aid",
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/D560BAQHDUNhDgBrKnw/company-logo_100_100/0/1680524181340?e=1705536000&v=beta&t=Z9A7vnhFMHZdXaHShZGkYTKZpvO_LuSwkDCZ401eO8s",
    school: "Purdue Global",
    date: "2020 - 2021",
    grade: "85 %",
    desc: "Activities and societies: Teach how to define factors for a company's success and determines potential risks in order to bring timely solutions.Activities and societies: Teach how to define factors for a company's success and determines potential risks in order to bring timely solutions.\nOrganizations should make it a point to manage complex information in order to architect an efficient project management system",
    degree: "Master, Information Technology",
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/C560BAQFuMLp85r2zzg/company-logo_100_100/0/1519870840685?e=1705536000&v=beta&t=HOvsMGB7ipoeDmfd0mu9gBcYggoLMqqrTvFDwZpPm0E",
    school: "Philanthropy University",
    date: "Oct 2020 - Apr 2021",
    grade: "Humanitarian specialist",
    desc: "Activities and societies: Project management, humanitarian data management, accountability and learning, capacity building, fundraising, Team leadership, monitoring and evaluation",
    degree: "Professional specialization, Non-Profit/Public/Organizational ManagementProfessional specialization, Non-Profit/Public/Organizational Management",
  },
  {
    id: 3,
    img: "https://media.licdn.com/dms/image/C560BAQHgdnQRM4ik9g/company-logo_100_100/0/1554835020033?e=1705536000&v=beta&t=u1xRmFEDUipxlgjor6mM2wtwCfZns17i92YU3FgczaE",
    school: "University of Rwanda",
    date: " xxxx - xxxx",
    grade: "80%",
    desc: "License",
    degree: "License, Geomatic",
  },
  {
    id: 4,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNHAyoaHCgIIF--pSPHKJRGQu2kocucmeoKWkvNQs5qtIu68-nI8IPDmRT2IM9JlOV8b4&usqp=CAU",
    school: "College Alfajiri",
    date: " xxxx - xxxx",
    grade: "81%",
    desc: "Diplôme d'accès aux études universitaires",
    degree: "Philosophy and Latin studiesDiplôme d'accès aux études universitaires, Philosophy and Latin studies",
  },
];