import React, {useState, useEffect} from 'react'
import "./style.css";
import { Container } from '../../components/Projects/ProjectsStyle';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { DeleteBlog, createBlog } from '../../api/blog';
import { useSapiens } from '../../context/sapiens';
import MDEditor from '@uiw/react-md-editor';
import { Link, useNavigate } from 'react-router-dom';

const customStyles = {
  content: {
    
    position: "absolute"
  },
};

function AdminPage() {
    const {setPending, blogs, setBlogs, pending} = useSapiens();
    const [loggedIn, setLoggedIn] = useState(false)
    const [password, setPassword] = useState("")
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [imageUrl, setImageURL] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState("");
    const [body, setBody] = useState("");
    const [blogType, setBlogType] = useState("BLOG")
    const navigate = useNavigate();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const FetchBlogs = async () => {

    }

    const SubmitPassword = (e) => {
        e.preventDefault();
        if( password === process.env.REACT_APP_ADMIN_PASSWORD){
            setLoggedIn(true)
            toast.success("welcome - Access to admin panel")
        }else{
            setLoggedIn(false)
            toast.error("Error! Wrong Password - No access")

        }
    }

    const HandleCreateBlogs = async (e) => {
        e.preventDefault();
        try {
          setPending(true);
          const data = { author: author, title: title, body: body, image: imageUrl, description: description, tags: tags, blogType: blogType };
          const formData = new FormData();
          Object.keys(data).forEach((key) => formData.append(key, data[key]));
          // console.log(formData);
          const res = await createBlog(formData);
          if (res.success) {
            setPending(false);
            toast.success("Blog created");
            setBlogs([...blogs, res?.data]);
            closeModal();
            setTitle("");
            setImageURL("");
            setAuthor("");
            setTags("");
            setDescription("");
            setBody("");
            setBlogType("BLOG")
          } else {
            // console.log(res)
            setPending(false);
            toast.error(res.message);
          }
        } catch (err) {
          setPending(false);
          toast.error(err.message);
        }
      };
    
    function HandleChangeImage(e) {
        const file = e.target.files[0];
        // console.log(file)
        if (!file.type?.includes("image")) {
            return toast.error("Erreur! ce format n'est pas reconnu");
        }
        setImageURL(file);
    }

    const HandleDeleteBlog = async (e, id) => {
        e.preventDefault();
        try {
          setPending(true);
          const res = await DeleteBlog(
            id,
            {},
          );
    
          if (res?.success) {
            // console.log(res);
            setPending(false);
            toast.success("deleted");
            setBlogs(blogs.filter((blog) => blog._id !== id));
          } else {
            setPending(false);
            toast.error(res?.message);
          }
        } catch (err) {
          setPending(false);
          toast.err(err.message);
        }
      };

    useEffect(() => {
        if (loggedIn === true){
            FetchBlogs()
        }
    }, [loggedIn])
    return (
        <Container>
            {
                !loggedIn ? (
                    <div className='d-flex justify-content-center'  style={{display: "flex", height: "70vh", alignItems: "center"}}>
                        <div className="form-container">
                            
                            <form className="form" onSubmit={SubmitPassword}>
                                <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" name="password" placeholder="Enter your password" required=""/>
                                </div>

                                <button className="form-submit-btn" type="submit">Access</button>
                            </form>
                            </div>
                    </div>
                ) : (
                    <div className='adminPage' style={{minHeight: "70vh"}}>
                        <div className='d-flex justify-content-center m-2'>
                        <button onClick={openModal} className='add-button'>
                        <span className="text">Create Blog</span>
                        </button>
                        </div>
                        <div className='row'>
                            {
                                blogs.map(bl => {
                                    return (
                                        <div key={bl._id} className="card col-lg-3 col-sm-12 col-md-6 m-1" style={{width: "18rem"}}>
                                        <img className="card-img" style={{height: "250px", objectFit: "cover"}} src={bl.image} alt="blog thumbnail"/>
                                        <div className="card-body" style={{height: "150px"}}>
                                            <h5 className="card-title">{bl.title}</h5>
                                            <p className="card-text">{bl.description.substr(0, 80)}, ...</p>
                                        </div>
                                        <div style={{justifyContent: "space-around"}} className="card-body d-flex">
                                            <Link className='btn btn-primary' to={`/blogs/${bl._id}`}>Read</Link>
                                            {/* <button className='btn btn-warning'>Edit</button> */}
                                            <button className='btn btn-danger' disabled={pending} onClick={(e) => HandleDeleteBlog(e, bl._id)}>Delete</button>
                                        </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className='' style={{display: 'flex', justifyContent: 'center'}}><button className='add-button' style={{zIndex: "99999"}} onClick={closeModal}><span>close</span></button>
                </div>
                <form className="container" onSubmit={HandleCreateBlogs}>
              <h2 className="text-center">Blog</h2>
                <hr/>
                <div className="d-flex justify-content-center">
                  <input
                    // value={imageUrlToCopy}
                    onChange={HandleChangeImage}
                    name="image"
                    type="file"
                    id="image"
                    hidden
                    className=""
                  />
                  <label className="cursor-pointer" htmlFor="image">
                    {imageUrl ? (
                      <img
                        style={{
                          height: "200px",
                          objectFit: "cover",
                          width: "200px",
                        }}
                        src={URL.createObjectURL(imageUrl)}
                        className=""
                        alt="uploaded-thumbnail"
                      />
                    ) : 
                    (
                      <div
                        className="d-flex w-100"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <div className="card p-2 m-2 border border-dashed border-gray-500 aspect-video text-gray-500 flex flex-col justify-center items-center">
                          <span className="text-center">Image</span>
                          <span className="text-center">
                            Selectionner Image
                          </span>
                          <span className="text-xs text-center">
                            Resolution recommendé
                          </span>
                          <span className="text-xs text-center">
                            1280 * 720
                          </span>
                        </div>
                      </div>
                    )}
                  </label>
                </div>

                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Tags EX: (project, research, geospatial, ...)"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
                <select className='form-control mt-2' value={blogType} onChange={(e) => setBlogType(e.target.value)}>
                  <option value="BLOG">Blog</option>
                  <option value="PROJECT">Project</option>
                </select>
                <textarea
                  className="form-control mt-2"
                  type="text"
                  placeholder="Short description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br/>
                <div className="container">
                <MDEditor
                  value={body}
                  onChange={setBody}
                />
                <MDEditor.Markdown source={body} style={{ whiteSpace: 'pre-wrap' }} />
              </div>
                <br/>
                <div className="d-flex justify-content-center">
                  <button type="submit" disabled={pending} className="btn btn-success">
                    {pending ? " . . . " : "Add"}
                  </button>
                </div>
              </form>
           
            </Modal>
            </div>
        </Container>
    )
}

export default AdminPage
