import React from 'react'
import "./style.css"
import Lottie from 'lottie-react';
import Animation from '../../assets/loadingAnimation.json'

function LoadingAnimation() {
    return (
        <div className="loading-container">
            <Lottie style={{width: '600px', height: "600px"}}
            animationData={Animation}
            loop={true}
            />
        </div>
    )
}

export default LoadingAnimation;