import React from 'react'
import styled from 'styled-components'
import HeroSection from '../../components/HeroSection'
import Skills from '../../components/Skills'
import Experience from '../../components/Experience'
import Projects from '../../components/Projects'
import Education from '../../components/Education'
import Contact from '../../components/Contact'
import ProjectDetails from '../../components/ProjectDetails'
import LoadingAnimation from '../../components/loading'

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(0, 82, 204, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`

function HomePage({openModal, HandleModal}) {
    return (
        <>
          <LoadingAnimation/>
            <HeroSection />
          <Wrapper>
            <Skills />
            <Experience />
          </Wrapper>
          <Projects isBlogsPage={false} openModal={openModal} setOpenModal={HandleModal} />
          <Wrapper>
            <Education />
            <Contact />
          </Wrapper>
          
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={HandleModal} />
          }
        </>
    )
}

export default HomePage
