import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSapiens } from '../../context/sapiens';
import { getBlog } from '../../api/blog';
import { toast } from 'react-toastify';
import { Title } from '../../components/Projects/ProjectsStyle';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import Markdown from 'react-markdown'
import './style.css'
const Tags = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
`

const Tag = styled.span`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primary + 15};
    padding: 2px 8px;
    border-radius: 10px;
`
const Details = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px 2px;
`

const Date = styled.div`
    font-size: 12px;
    margin-left: 2px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary + 80};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`
function BlogPage() {
    const param = useParams();
    const [blog, setBlog] = useState(false)
    const {setPending} = useSapiens();
    
    const FetchBlog = async() => {
        try{
            setPending(true)
            const res = await getBlog(param.id);
            if (res.success){
                setBlog(res.data)
            setPending(false)

            }else{
            setPending(false)

                toast.error("Error! Try again later")
            
            }
        }catch(err){
            setPending(false)

            toast.error("Error! Try again later")
        }
    }

    useEffect(() => {
        FetchBlog()
    }, [])

    return (
       <>
       {
        blog ?
        <div style={{minHeight: '70vh'}}>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img style={{width: "90vw",  maxWidth: "700px", objectFit: "cover"}} src={blog?.image} alt="blog thumbnail"></img>
        </div>
        <div style={{display: "flex", justifyContent: 'center'}}>
        <Tags>
            {blog?.tags?.split(",")?.map((tag, index) => (
            <Tag key={`${tag}-${index}`}>{tag}</Tag>
            ))}
        </Tags>
        </div>
        <Details>
            <Title>{blog?.title}</Title>
        <div style={{display: "flex", justifyContent: 'center'}}>
        <Date>Author: {blog?.author.toUpperCase()} {""}</Date>
        </div>
        <div style={{display: "flex", justifyContent: 'center'}}>
        <Date>{dateFormat(blog?.createdAt, "dd - mmmm - yyyy")}</Date>
        </div>
        </Details>

        <div className="body-content container">
            <Markdown>
                {blog.body}
            </Markdown>
        </div>
    </div>
    : null
       }
       </>
    )
}

export default BlogPage
