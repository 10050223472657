import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import styled from "styled-components";
import HomePage from "./pages/home/index.js";
import Footer from './components/Footer'
import NotfoundPage from "./pages/notfound/index.js";
import AdminPage from "./pages/admin/index.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogPage from "./pages/blog/index.js";
import BlogsPage from "./pages/blogs/index.js";
import LoadingAnimation from "./components/loading/index.js";
import { useSapiens } from "./context/sapiens.js";
import PendingAnimation from "./components/pendingAnimation/index.js";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`


function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  const {pending} = useSapiens()
  // console.log(location)
  const HandleDarkMode = (value) => {
    setDarkMode(value);
  }

  const HandleModal = (value) => {
    setOpenModal(value)
  }

  // console.log(openModal)
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>

      <Router >
        <Navbar />
        <Body>
          
          {pending ? <PendingAnimation/> : null}
          <Routes>
            <Route exact path='/' element={<HomePage openModal={openModal} modalHandle={HandleModal}/>}/>
            <Route path="/admin" element={<AdminPage/>} />
            <Route path="/blogs/:id" element={<BlogPage/>} />
            <Route path="/blogs" element={<BlogsPage/>} />
            <Route  path='*' element={<NotfoundPage/>}/>

          </Routes>
          <Footer />
          <ToastContainer/>
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
