import React from 'react'
import "./style.css"
import Lottie from 'lottie-react';
import Animation from '../../assets/loading2.json'

function PendingAnimation() {

    return (
        <div className="loading-container-2">
            <Lottie style={{width: '400px', height: "400px"}}
            animationData={Animation}
            loop={true}
            
            />
        </div>
    )
}

export default PendingAnimation
