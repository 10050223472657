import client from "./client";

export const getBlogs = async () => {
    try{
        const {data} = await client.get("/api/blog/all");
        return data;
    }catch (error) {
        const { response } = error;
        if (response?.data) {
          return response.data;
        }
        return { error: error.message || error };
    }
}

export const getBlog = async (id) => {
    try{
        const {data} = await client.get(`/api/blog/single/${id}`);
        return data;
    }catch (error) {
        const { response } = error;
        if (response?.data) {
          return response.data;
        }
        return { error: error.message || error };
    }
}

export const createBlog = async (blog_data) => {
    try{
        const {data} = await client.post("/api/blog/create", blog_data);
        return data;
    }catch (error) {
        const { response } = error;
        if (response?.data) {
          return response.data;
        }
        return { error: error.message || error };
    }
}
export const DeleteBlog = async (id, body) => {
    try{
        const {data} = await client.put(`/api/blog/delete/${id}`, body);

        if(data){
            return data;
        }else{
            return null;
        }
    }catch(err) {
        // console.log(err);
        return {success: false, message: err?.message};
    }
}