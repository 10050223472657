import React from 'react';
import { useState } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';
import { useSapiens } from '../../context/sapiens';
import { Link } from 'react-router-dom';


const Projects = ({openModal,setOpenModal, isBlogsPage}) => {
  const [toggle, setToggle] = useState('all');
  const {blogs} = useSapiens();

  return (
    <Container id="blogs">
      <Wrapper>
        <Title>Blogs</Title>
        <Desc>
          Personnal Blog
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider />
          {toggle === 'blogs' ?
            <ToggleButton active value="blogs" onClick={() => setToggle('blogs')}>Blogs</ToggleButton>
            :
            <ToggleButton value="blogs" onClick={() => setToggle('blogs')}>Blogs</ToggleButton>
          }
          <Divider />
          {toggle === 'projects' ?
            <ToggleButton active value="projects" onClick={() => setToggle('projects')}>Projects</ToggleButton>
            :
            <ToggleButton value="projects" onClick={() => setToggle('projects')}>Projects</ToggleButton>
          }
          
        </ToggleButtonGroup>
        <CardContainer>
            {/* TODO: REFACTOR AND ARRANGE THE CODE LATER*/}
          {
            toggle === 'all' ? blogs
            .map((project, index) => {
                if (isBlogsPage){
                  return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                  
                }else {
                  if(index < 5){
                    return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                  }
                }
                return null
            }) : 
            (toggle === 'projects' ?
            blogs
            .filter(blog => blog.blogType === "PROJECT")
            .map((project, index) => {
              if (isBlogsPage){
                return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                
              }else {
                if(index < 5){
                  return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                }
              }
              return null
          }): blogs
            .filter(blog => blog.blogType === "BLOG")
            .map((project, index) => {
              if (isBlogsPage){
                return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                
              }else {
                if(index < 5){
                  return <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                }
              }
              return null
          }))
          }
          
        </CardContainer>
        {
          isBlogsPage === false ? <>
            <Link to="/blogs" className="add-button" style={{PaddingBlock: "20px"}}>
            <span className="">See More</span>
            </Link>
          </> : null
        }
      </Wrapper>
    </Container>
  )
}

export default Projects