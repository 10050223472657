import {createContext, useContext, useEffect, useState} from 'react';
import { getBlogs } from '../api/blog';
import { toast } from 'react-toastify';

const MainContext = createContext();

function MainProvider({children}){
    const [pending, setPending] = useState(true)
    const [blogs, setBlogs] = useState([])

    const FetchBlogs = async () => {
        try {
          setPending(true);
          const response = await getBlogs();
    
          if (response?.success) {
            setPending(false);
            // console.log(response.data)
            setBlogs(response?.data);
          } else {
            setPending(false);
            toast.error(response?.message);
          }
        } catch (err) {
          // console.log(err);
          setPending(false);
          toast.error("Erreur! Error Fetching blogs");
        }
      };
    
    useEffect(() => {
        FetchBlogs()
    }, [])

    

    return (
        <MainContext.Provider value={{blogs, setBlogs, pending, setPending, FetchBlogs}}>
            {children}
        </MainContext.Provider>
    )
}

export const useSapiens = () => useContext(MainContext)

export default MainProvider;