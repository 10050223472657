import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../components/Projects/ProjectsStyle'

function NotfoundPage() {
    return (
        <Container>
            <h2 style={{color: "white"}}>ERROR</h2>
            <h3 style={{color: "white"}}>Page not found</h3>
            <Link style={{color: "white"}} to="/">Go Back to Home Page</Link>
        </Container>
    )
}

export default NotfoundPage
